



































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GET_USER_DETAILS,
  LOGOUT_USER,
  USER_PROFILE,
  USER_PROFILE_BY_ID,
  USER_STATE
} from "@/store/modules/auth/constants";
import { wait_until } from "@/utils/global";
import { UserState } from "@/store/modules/auth/interfaces";

export default Vue.extend({
  name: "ProfileLoading",
  data() {
    return {
      loading_animation: require("@/assets/illustrations/loading.json"),
      fetching: true
    };
  },
  created() {
    this.loading_animation = JSON.stringify(this.loading_animation);
  },
  async mounted() {
    const uid = this.get_user.id;
    // Pooling to fetch user profile after 6 seconds
    while (this.fetching) {
      if (this.get_user_state !== UserState.CANDIDATE_PROFILE_PENDING) break;
      const response = await this.fetch_profile(uid);
      // If profile fetched
      if (response) {
        this.fetching = false;
        this.set_user_state(UserState.CANDIDATE); // Update user state
        this.set_user_profile(response); // Set user profile
        await this.$router.push("/candidate/dashboard"); // Navigate to candidate dashboard
        break;
      }
      await wait_until(6000); // Wait 6 seconds
    }
  },
  computed: {
    ...mapGetters("auth", {
      get_user: GET_USER_DETAILS,
      get_user_state: USER_STATE
    })
  },
  methods: {
    ...mapActions("auth", {
      logout_user: LOGOUT_USER,
      fetch_profile: USER_PROFILE_BY_ID
    }),
    ...mapMutations("auth", {
      set_user_profile: USER_PROFILE,
      set_user_state: USER_STATE
    }),
    async _logout_user() {
      await this.logout_user();
      await this.$router.push("/auth/login");
    }
  }
});
